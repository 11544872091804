const FormRulesMixin = {
  data: function () {
    return {
      emailRules: [
        (v) => !!v || this.$t("form.validation.required", {field: this.$t('form.label.email')}),
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*/.test(v) ||
          this.$t('form.validation.valid', {field: this.$t('form.label.email')})
      ],
      nameRules: [
        (v) => !!v || this.$t("form.validation.required", {field: this.$t('form.label.name')}),
        (v) =>
          (v && v.length <= 10) ||
            this.$t('form.validation.maxLength', {
              field: this.$t('form.label.name'),
              length: 10
            }),
      ],
      siteNameRules: [
        (v) => !!v || this.$t("form.validation.required", {field: this.$t('form.label.siteName')}),
        (v) =>
          (v && v.length <= 25) ||
            this.$t('form.validation.maxLength', {
              field: this.$t('form.label.siteName'),
              length: 25
            }),
      ],
      domainRules: [
        (v) => !!v || this.$t("form.validation.required", {field: this.$t('form.label.domain')}),
        (v) =>
          (v && v.length <= 63) ||
            this.$t('form.validation.maxLength', {
              field: this.$t('form.label.domain'),
              length: 64
            }),
        (v) =>
          (v && v.length >= 4) ||
            this.$t('form.validation.minLength', {
              field: this.$t('form.label.domain'),
              length: 3
            }),
        // domain can contain letters, numbers dots hyphen
        (v) => /^[a-zA-Z0-9.-]*$/.test(v) ||
          this.$t('form.validation.valid', {field: this.$t('form.label.domain')})
      ],
      domainRulesTld: [
        (v) => !!v || this.$t("form.validation.required", {field: this.$t('form.label.domain')}),
        (v) =>
          (v && v.length <= 63) ||
            this.$t('form.validation.maxLength', {
              field: this.$t('form.label.domain'),
              length: 64
            }),
        (v) =>
          (v && v.length >= 4) ||
            this.$t('form.validation.minLength', {
              field: this.$t('form.label.domain'),
              length: 3
            }),
        // domain can contain letters, numbers dots hyphen
        (v) => /[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*\.[a-zA-Z]{2,9}(:[0-9]{1,5})?(\/[a-zA-Z0-9_~-]+)*$/.test(v) ||
          this.$t('form.validation.valid', {field: this.$t('form.label.domain')})
      ],
      checkboxRules: [(v) => !!v || this.$t("form.validation.required", {field: this.$t('form.label.item')}),],
      adminPasswordRules: [
        (v) => !!v || this.$t("form.validation.required", {field: this.$t('form.label.password')}),
        (v) =>
          (v && v.length <= 25) ||
            this.$t('form.validation.maxLength', {
              field: this.$t('form.label.password'),
              length: 25
            }),
        (v) =>
          (v && v.length >= 6) ||
            this.$t('form.validation.minLength', {
              field: this.$t('form.label.password'),
              length: 6
            }),
      ],
      registerPasswordRules: [
        (v) => !!v || this.$t("form.validation.required", {field: this.$t('form.label.password')}),
        (v) =>
          (v && v.length <= 25) ||
            this.$t('form.validation.maxLength', {
              field: this.$t('form.label.password'),
              length: 25
            }),
        (v) =>
          (v && v.length >= 8) ||
            this.$t('form.validation.minLength', {
              field: this.$t('form.label.password'),
              length: 8
            }),
      ],
    };
  },
};

export default FormRulesMixin;
